import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { injectGlobal } from 'emotion'
import styled from 'react-emotion'
import { Layout, Menu, Icon, Popover } from 'antd'

import Logo from './logo'

const { SubMenu } = Menu

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  .ant-layout-header {
    @media screen and (max-width: 1023px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .ant-menu-submenu-title > a {
    color: rgba(255, 255, 255, 0.65);
  }

  .ant-menu-submenu-active > div > a {
    background: transparent;
    color: #ffffff;
  }

  .popover-menu {
    width: 100vw;
    padding-top: 16px;
    .ant-popover-inner-content {
      padding: 0 0;
    }

    .ant-popover-inner {
      overflow: hidden;
      border-radius: 0px;
    }

    .ant-popover-arrow {
      display: none;
    }

    #nav a.header-link {
      color: #333;
    }
  }

  .nav-phone-icon {
    color: #fff;
  }

  th.ant-table-fixed-columns-in-body > i.anticon {
    display: none;
  }

  .ant-layout-content .ant-tabs {
     overflow: visible;
  }
`

const StyledLogo = styled(Logo)`
  width: 105px;
  height: 50px;
`

const LogoContainer = styled.div`
  padding-top: 7px;
  padding-right: 30px;
  height: 64px;
  float: left;
`

const MenuContainer = styled.div`
  z-index: 2;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  width: 100%;
  text-align: right;
  display: none;
  z-index: 2;

  @media screen and (max-width: 599px) {
    display: block;
  }
`

function trimPath(path) {
  const first = path.split('/').find(item => item !== '')
  return `/${first || ''}`
}

class Header extends Component {
  state = {
    menuVisible: false,
  }

  handleShowMenu = () => {
    this.setState({
      menuVisible: true,
    })
  }

  onMenuVisibleChange = visible => {
    this.setState({
      menuVisible: visible,
    })
  }

  onMobileMenuClick = () => {
    this.setState({
      menuVisible: false,
    })
  }

  render() {
    const { siteTitle, location } = this.props
    const { menuVisible } = this.state
    return (
      <Layout.Header>
        <MenuContainer>
          <LogoContainer>
            <Link to="/">
              <StyledLogo alt={siteTitle} />
            </Link>
          </LogoContainer>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['/']}
            selectedKeys={[trimPath(location.pathname)]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="/">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="/articles">
              <Link to="/articles">Articles</Link>
            </Menu.Item>
            <SubMenu
              style={{ marginBottom: '0px' }}
              key="/leaderboards"
              title={<Link to="/events/cwl-champs-2019/leaderboard">Leaderboards</Link>}
            >
              <Menu.Item key="/events/cwl-champs-2019/leaderboard">
                <Link to="/events/cwl-champs-2019/leaderboard">CWL Champs</Link>
              </Menu.Item>
              <Menu.Item key="/events/pro-league-finals-2019/leaderboard">
                <Link to="/events/pro-league-finals-2019/leaderboard">Pro League Finals</Link>
              </Menu.Item>
              <Menu.Item key="/events/cwl-anaheim-2019/leaderboard">
                <Link to="/events/cwl-anaheim-2019/leaderboard">Anaheim</Link>
              </Menu.Item>
              <Menu.Item key="/events/cwl-london-2019/leaderboard">
                <Link to="/events/cwl-london-2019/leaderboard">London</Link>
              </Menu.Item>
              <Menu.Item key="/events/cwl-pro-league-2019/leaderboard">
                <Link to="/events/cwl-pro-league-2019/leaderboard">Pro League</Link>
              </Menu.Item>
              <Menu.Item key="/events/cwl-fort-worth-2019/leaderboard">
                <Link to="/events/cwl-fort-worth-2019/leaderboard">Fort Worth</Link>
              </Menu.Item>
              <Menu.Item key="/events/cwl-pro-league-qualifier-2019/leaderboard">
                <Link to="/events/cwl-pro-league-qualifier-2019/leaderboard">PLQ Columbus</Link>
              </Menu.Item>
              <Menu.Item key="/events/cwl-las-vegas-2019/leaderboard">
                <Link to="/events/cwl-las-vegas-2019/leaderboard">Las Vegas</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/about">
              <Link to="/about">About</Link>
            </Menu.Item>
          </Menu>
        </MenuContainer>
        <MobileMenuContainer>
          <LogoContainer>
            <Link to="/">
              <StyledLogo alt={siteTitle} />
            </Link>
          </LogoContainer>
          <Popover
            overlayClassName="popover-menu"
            placement="bottomRight"
            content={
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['/']}
                selectedKeys={[trimPath(location.pathname)]}
                style={{ lineHeight: '64px' }}
              >
                <Menu.Item key="/">
                  <Link to="/" onClick={this.onMobileMenuClick}>
                    Home
                  </Link>
                </Menu.Item>
                <Menu.Item key="/articles">
                  <Link to="/articles" onClick={this.onMobileMenuClick}>
                    Articles
                  </Link>
                </Menu.Item>
                <SubMenu
                  style={{ marginBottom: '0px' }}
                  key="/leaderboards"
                  title={<span>Leaderboards</span>}
                >
                  <Menu.Item key="/events/cwl-champs-2019/leaderboard">
                    <Link to="/events/cwl-champs-2019/leaderboard" onClick={this.onMobileMenuClick}>
                      CWL Champs
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/events/pro-league-finals-2019/leaderboard">
                    <Link
                      to="/events/pro-league-finals-2019/leaderboard"
                      onClick={this.onMobileMenuClick}
                    >
                      Pro League Finals
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/events/cwl-anaheim-2019/leaderboard">
                    <Link
                      to="/events/cwl-anaheim-2019/leaderboard"
                      onClick={this.onMobileMenuClick}
                    >
                      Anaheim
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/events/cwl-london-2019/leaderboard">
                    <Link to="/events/cwl-london-2019/leaderboard" onClick={this.onMobileMenuClick}>
                      London
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/events/cwl-pro-league-2019/leaderboard">
                    <Link
                      to="/events/cwl-pro-league-2019/leaderboard"
                      onClick={this.onMobileMenuClick}
                    >
                      Pro League
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/events/cwl-fort-worth-2019/leaderboard">
                    <Link
                      to="/events/cwl-fort-worth-2019/leaderboard"
                      onClick={this.onMobileMenuClick}
                    >
                      Fort Worth
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/events/cwl-pro-league-qualifier-2019/leaderboard">
                    <Link
                      to="/events/cwl-pro-league-qualifier-2019/leaderboard"
                      onClick={this.onMobileMenuClick}
                    >
                      PLQ Columbus
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/events/cwl-las-vegas-2019/leaderboard">
                    <Link
                      to="/events/cwl-las-vegas-2019/leaderboard"
                      onClick={this.onMobileMenuClick}
                    >
                      Las Vegas
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="/about">
                  <Link to="/about" onClick={this.onMobileMenuClick}>
                    About
                  </Link>
                </Menu.Item>
              </Menu>
            }
            trigger="click"
            visible={menuVisible}
            arrowPointAtCenter
            onVisibleChange={this.onMenuVisibleChange}
          >
            <Icon className="nav-phone-icon" type="menu" onClick={this.handleShowMenu} />
          </Popover>
        </MobileMenuContainer>
      </Layout.Header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
}

export default Header
