import styled from 'react-emotion'

const TeamName = styled('span')`
  display: inline-block;
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 400px) {
    width: 50px;
  }

  @media screen and (max-width: 600) {
    width: 75px;
  }

  @media screen and (max-width: 959) {
    width: 125px;
  }
`

export default TeamName
