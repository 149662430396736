import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

const columns = [
  {
    dataIndex: 'Team',
    key: 'Rank',
    fixed: 'left',
    render: (text, record, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
    fixed: 'left',
    render: text => (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
  },
  {
    title: <span style={{ marginLeft: '12px' }}>K/D</span>,
    dataIndex: 'SNDKD',
    key: 'SNDKD',
    sorter: (a, b) => a.SNDKD - b.SNDKD,
    render: text => <span style={{ marginLeft: '12px' }}>{numeral(text).format('0.00')}</span>,
    defaultSortOrder: 'descend',
  },
  {
    title: 'SPR',
    dataIndex: 'SNDSPR',
    key: 'SNDSPR',
    sorter: (a, b) => a.SNDSPR - b.SNDSPR,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'DPR',
    dataIndex: 'SNDDPR',
    key: 'SNDDPR',
    sorter: (a, b) => a.SNDDPR - b.SNDDPR,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'FB%',
    dataIndex: 'SNDFBRate',
    key: 'SNDFBRate',
    sorter: (a, b) => a.SNDFBRate - b.SNDFBRate,
    render: text => numeral(text).format('00.00%'),
  },
  {
    title: 'Heal%',
    dataIndex: 'SNDHeal',
    key: 'SNDHeal',
    sorter: (a, b) => a.SNDHeal - b.SNDHeal,
    render: text => numeral(text).format('00.00%'),
  },
  {
    title: 'RndWin%',
    dataIndex: 'SNDRoundWin',
    key: 'SNDRoundWin',
    sorter: (a, b) => a.SNDRoundWin - b.SNDRoundWin,
    render: text => numeral(text).format('00.00%'),
  },
  {
    title: 'Rounds',
    dataIndex: 'SNDRounds',
    key: 'SNDRounds',
    sorter: (a, b) => a.CTLRounds - b.CTLRounds,
  },
]

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

const SnD = ({ showKey, onCancel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 28, size: 'default' }}
        rowClassName={striped}
        scroll={{ x: true }}
        rowKey={row => `${row.Team}`}
      />
      <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
        <ul>
          <li>
            <strong>K/D</strong> - Kill/Death Ratio
          </li>
          <li>
            <strong>SPR</strong> - Score Per Round
          </li>
          <li>
            <strong>DPR</strong> - Damage Per Round
          </li>
          <li>
            <strong>FB%</strong> - First Blood Percentage
          </li>
          <li>
            <strong>Heal%</strong> - Percentage of Opponent Damage Healed
          </li>
          <li>
            <strong>RndWin%</strong> - Round Win Percentage
          </li>
          <li>
            <strong>Rounds</strong> - Rounds Played
          </li>
        </ul>
      </Modal>
    </div>
  )
}

SnD.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default SnD
