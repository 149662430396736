import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'react-emotion'
import { Layout } from 'antd'

const Logo = styled('div')`
  display: block;
  font-weight: bold;
  width: 120px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const Spacer = styled('div')`
  display: block;
  font-weight: bold;
  width: 120px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const StyledFooter = styled(Layout.Footer)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: no-wrap;

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
`

const CopyrightContainer = styled('div')`
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: no-wrap;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const Footer = ({ siteTitle }) => (
  <StyledFooter>
    <Logo>
      <Link
        to="/"
        style={{
          color: 'black',
          textDecoration: 'none',
        }}
      >
        {siteTitle}
      </Link>
    </Logo>
    <CopyrightContainer>
      <span style={{ padding: '0px 2px' }}>CoD Stats ©2018</span>
      <span style={{ padding: '0px 2px' }}>
        Created by <a href="https://stephengfriend.com">Stephen G. Friend</a>
      </span>
    </CopyrightContainer>
    <Spacer>&nbsp;</Spacer>
  </StyledFooter>
)

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
}

export default Footer
