import React from 'react'
import PropTypes from 'prop-types'

const Paragraph = ({ children, style }) => (
  <p style={Object.assign({ fontSize: '16px' }, style)}>{children}</p>
)

Paragraph.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
}

Paragraph.defaultProps = {
  children: [],
  style: {},
}

export default Paragraph
