import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

const columns = [
  {
    dataIndex: 'Team',
    key: 'Rank',
    fixed: 'left',
    render: (text, record, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
    fixed: 'left',
    render: text => (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
  },
  {
    title: <span style={{ marginLeft: '12px' }}>K/D</span>,
    dataIndex: 'CTLKD',
    key: 'CTLKD',
    sorter: (a, b) => a.CTLKD - b.CTLKD,
    render: text => <span style={{ marginLeft: '12px' }}>{numeral(text).format('0.00')}</span>,
    defaultSortOrder: 'descend',
  },
  {
    title: 'SPM',
    dataIndex: 'CTLSPM',
    key: 'CTLSPM',
    sorter: (a, b) => a.CTLSPM - b.CTLSPM,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'DPM',
    dataIndex: 'CTLDPM',
    key: 'CTLDPM',
    sorter: (a, b) => a.CTLDPM - b.CTLDPM,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'RndWin',
    dataIndex: 'CTLRoundWin',
    key: 'CTLRoundWin',
    sorter: (a, b) => a.CTLRoundWin - b.CTLRoundWin,
    render: text => numeral(text).format('00.00%'),
  },
  {
    title: 'Rounds',
    dataIndex: 'CTLRounds',
    key: 'CTLRounds',
    sorter: (a, b) => a.CTLRounds - b.CTLRounds,
  },
]

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

const Control = ({ showKey, onCancel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 28, size: 'default' }}
        rowClassName={striped}
        scroll={{ x: true }}
        rowKey={row => `${row.Team}`}
      />
      <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
        <ul>
          <li>
            <strong>K/D</strong> - Kill/Death Ratio
          </li>
          <li>
            <strong>SPM</strong> - Score Per Minute
          </li>
          <li>
            <strong>DPM</strong> - Damage Per Minute
          </li>
          <li>
            <strong>RndWin%</strong> - Round Win Percentage
          </li>
          <li>
            <strong>Rounds</strong> - Rounds Played
          </li>
        </ul>
      </Modal>
    </div>
  )
}

Control.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default Control
