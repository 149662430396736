import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import numeral from 'numeral'
import { Table, Modal } from 'antd'

import TeamName from '../teamname'

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

class Control extends Component {
  state = {
    offset: 0,
  }

  columns = [
    {
      dataIndex: 'Player',
      key: 'Rank',
      fixed: 'left',
      render: (text, record, idx) => {
        const { offset } = this.state
        return <span>{offset + idx + 1}</span>
      },
    },
    {
      title: 'Player',
      dataIndex: 'player',
      key: 'Player',
      sorter: (a, b) => {
        if (a.Player.toLowerCase() < b.Player.toLowerCase()) return -1
        if (a.Player.toLowerCase() > b.Player.toLowerCase()) return 1
        return 0
      },
      render: (text, record) => (
        <div>
          <span style={{ fontWeight: 'bold' }}>{record.Player}</span>
          <br />
          <TeamName>{record.Team}</TeamName>
        </div>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'ControlRating',
      key: 'ControlRating',
      sorter: (a, b) => numeral(a.ControlRating).value() - numeral(b.ControlRating).value(),
      render: text => numeral(text).format('0.00'),
      defaultSortOrder: 'descend',
    },
    {
      title: 'K/D',
      dataIndex: 'ControlKD',
      key: 'ControlKD',
      sorter: (a, b) => numeral(a.ControlKD).value() - numeral(b.ControlKD).value(),
      render: text => numeral(text).format('0.00'),
    },
    {
      title: 'SPM',
      dataIndex: 'ControlSPM',
      key: 'ControlSPM',
      sorter: (a, b) => numeral(a.ControlSPM).value() - numeral(b.ControlSPM).value(),
      render: text => numeral(text).format('0'),
    },
    {
      title: 'DPM',
      dataIndex: 'ControlDPM',
      key: 'ControlDPM',
      sorter: (a, b) => numeral(a.ControlDPM).value() - numeral(b.ControlDPM).value(),
      render: text => numeral(text).format('0'),
    },
    {
      title: 'EPM',
      dataIndex: 'ControlEPM',
      key: 'ControlEPM',
      sorter: (a, b) => numeral(a.ControlEPM).value() - numeral(b.ControlEPM).value(),
      render: text => numeral(text).format('0.00'),
    },
    {
      title: 'CLU',
      dataIndex: 'CLU',
      key: 'CLU',
      sorter: (a, b) => numeral(a.CLU).value() - numeral(b.CLU).value(),
      render: text => numeral(text).format('00.00%'),
    },
    {
      title: 'DeathPM',
      dataIndex: 'ControlDeathPM',
      key: 'ControlDeathPM',
      sorter: (a, b) => numeral(a.ControlDeathPM).value() - numeral(b.ControlDeathPM).value(),
      render: text => numeral(text).format('0.00'),
    },
    {
      title: 'Maps',
      dataIndex: 'ControlMaps',
      key: 'ControlMaps',
      sorter: (a, b) => numeral(a.ControlMaps).value() - numeral(b.ControlMaps).value(),
    },
  ]

  onChange = (page, pageSize) => this.setState({ offset: (page - 1) * pageSize || 0 })

  render() {
    const {
      props: { tableData, showKey, onCancel },
      onChange,
    } = this

    const columnsWithTeamsFilter = columns => {
      const players = tableData.map(row => [row.Team, row.Player])
      const teams = tableData.map(row => row.Team)
      const cols = columns.slice(0)
      const filters = Array.from(new Set(teams)).map(team => ({
        text: team,
        value: `${team}SubMenu`,
        children: players
          .reduce(
            (acc, [playerTeam, player]) => {
              if (team === playerTeam) {
                acc.push({ text: player, value: player })
              }

              return acc
            },
            [{ text: 'All', value: team }]
          )
          .sort((a, b) => {
            if (a.text === 'All') return -1
            if (b.text === 'All') return 1
            if (a.text.toLowerCase() < b.text.toLowerCase()) return -1
            if (a.text.toLowerCase() > b.text.toLowerCase()) return 1
            return 0
          }),
      }))

      cols[1].filters = filters.sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) return -1
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1
        return 0
      })

      cols[1].onFilter = (value, record) =>
        record.Team.indexOf(value) === 0 || record.Player.indexOf(value) === 0

      return cols
    }

    return (
      <div>
        <Table
          dataSource={tableData}
          columns={columnsWithTeamsFilter(this.columns)}
          size="small"
          pagination={{ pageSize: 20, size: 'default', onChange }}
          rowClassName={striped}
          scroll={{ x: true }}
          rowKey={row => `${row.Player}-${row.Team}`}
        />
        <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
          <ul>
            <li>
              <strong>Rating</strong> -{' '}
              <Link to="/articles/introducing-codstats-rating">Codstats Rating</Link>
            </li>
            <li>
              <strong>K/D</strong> - Kill/Death Ratio
            </li>
            <li>
              <strong>SPM</strong> - Score Per Minute
            </li>
            <li>
              <strong>DPM</strong> - Damage Per Minute
            </li>
            <li>
              <strong>EPM</strong> - Engagements Per Minute
            </li>
            <li>
              <strong>CLU</strong> - Control Lives Used - Percentage of Teams 30 deaths per Round
            </li>
            <li>
              <strong>DeathPM</strong> - Deaths Per Minute
            </li>
            <li>
              <strong>Maps</strong> - Control Maps Played
            </li>
          </ul>
        </Modal>
      </div>
    )
  }
}

Control.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default Control
