import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

const columns = [
  {
    dataIndex: 'Team',
    key: 'Rank',
    fixed: 'left',
    render: (text, record, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
    fixed: 'left',
    render: text => (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
  },
  {
    title: <span style={{ marginLeft: '12px' }}>K/D</span>,
    dataIndex: 'HPKD',
    key: 'HPKD',
    sorter: (a, b) => a.HPKD - b.HPKD,
    render: text => <span style={{ marginLeft: '12px' }}>{numeral(text).format('0.00')}</span>,
    defaultSortOrder: 'descend',
  },
  {
    title: 'SPM',
    dataIndex: 'HPSPM',
    key: 'HPSPM',
    sorter: (a, b) => a.HPSPM - b.HPSPM,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'DPM',
    dataIndex: 'HPDPM',
    key: 'HPDPM',
    sorter: (a, b) => a.HPDPM - b.HPDPM,
    render: text => numeral(text).format('0'),
  },
  {
    title: 'KillEff',
    dataIndex: 'KillEff',
    key: 'KillEff',
    sorter: (a, b) => a.KillEff - b.KillEff,
    render: text => numeral(text).format('0.00'),
  },
  {
    title: 'Maps',
    dataIndex: 'HPMaps',
    key: 'HPMaps',
    sorter: (a, b) => a.HPMaps - b.HPMaps,
  },
]

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

const Hardpoint = ({ showKey, onCancel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 28, size: 'default' }}
        rowClassName={striped}
        scroll={{ x: true }}
        rowKey={row => `${row.Team}`}
      />
      <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
        <ul>
          <li>
            <strong>K/D</strong> - Kill/Death Ratio
          </li>
          <li>
            <strong>SPM</strong> - Score Per Minute
          </li>
          <li>
            <strong>DPM</strong> - Damage Per Minute
          </li>
          <li>
            <strong>KillEff</strong>
            {' - Kill Efficiency - Hill Points Per 5 Kills ([points / kills] x 5)'}
          </li>
          <li>
            <strong>Maps</strong> - Maps Played
          </li>
        </ul>
      </Modal>
    </div>
  )
}

Hardpoint.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default Hardpoint
