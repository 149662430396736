import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Layout as AntdLayout } from 'antd'

import Header from './header'
import Footer from './footer'

const { Content } = AntdLayout

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'CoD Stats - Analytics You Can Trust' },
            {
              name: 'keywords',
              content:
                'Call of Duty, CoD, cod, Black Ops, Black Ops 4, CWL, Call of Duty World League',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <AntdLayout>
          <Header siteTitle={data.site.siteMetadata.title} location={location} />
          <Content style={{ backgroundColor: '#ffffff', minHeight: 'calc(100vh - 69px - 64px' }}>
            {children}
          </Content>
          <Footer siteTitle={data.site.siteMetadata.title} />
        </AntdLayout>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default Layout
